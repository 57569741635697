import Highway from '@dogstudio/highway';
import FadeTransition from './transitions/fade';
import TransformTransition from './transitions/transform';
import DefaultRenderer from './renderers/default';
import HomeRenderer from './renderers/home';
// import * as Navigation from './nav';

const H = new Highway.Core({
    renderers: {
        default: DefaultRenderer,
        home: HomeRenderer,
    },
    transitions: {
        default: FadeTransition,
        contextual: {
            transform: TransformTransition,
        }
    }
});


// Navigation.init();
// const links = document.querySelectorAll('.m-nav a');
H.on('NAVIGATE_IN', ({ to, trigger, location }) => {
});
H.on('NAVIGATE_OUT', ({ from, trigger, location }) => {
    // for (let i = 0; i < links.length; i++) {
    //     const link = links[i];
    //     link.classList.remove('is-active');
    //     if (link.href === location.href) {
    //       link.classList.add('is-active');
    //     }
    // }
});
H.on('NAVIGATE_END', ({ to, from, trigger, location }) => {
});



    
