import Highway from '@dogstudio/highway';
import anime from 'animejs';

class TransformTransition extends Highway.Transition {
  // Built-in methods
  in({ from, to, trigger, done }) {
    window.scrollTo(0, 0);
    from.remove();
    anime({
        targets: to,
        translateY: ['14%', 0],
        opacity: [0, 1],
        easing: 'cubicBezier(0.43, 0.195, 0.02, 1)',
        complete: done
    });
  }
  out({ from, trigger, done }) {
    anime({
        targets: from,
        // scale: [1, 2],
        translateY: [0,'24%'],
        opacity: [1, 0],
        easing: 'cubicBezier(0.43, 0.195, 0.02, 1)',
        complete: done
      })
  }
}

// Don`t forget to export your transition
export default TransformTransition;