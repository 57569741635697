import Highway from '@dogstudio/highway'
import * as Swiper from '../swiper';

class DefaultRenderer extends Highway.Renderer {
  // Hooks/methods
  onEnter() {
    Swiper.init();
  }
  onLeave() {
    
  }
  onEnterCompleted() {
    
  }
  onLeaveCompleted() {

  }
  
}

// Don`t forget to export your renderer
export default DefaultRenderer;