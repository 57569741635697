import Highway from '@dogstudio/highway'

class HomeRenderer extends Highway.Renderer {
  // Hooks/methods
  onEnter() {
    
    console.log(window.screen.width)
  }
  onLeave() {
    
  }
  onEnterCompleted() {
    var title = document.getElementById('title');
    var insta = document.getElementById('insta');
    var intro = document.getElementById('intro');
    var close = document.getElementById('close');
    
    if(title) {
        if(window.innerWidth <= 1199) {
            title.addEventListener('click', function() {
                title.classList.toggle('active');
            }, false);
            close.addEventListener('click', function() {
                title.classList.toggle('active');
            }, false);
        }
        if(window.innerWidth >= 1200) {
            title.addEventListener('mouseenter', function() {
                title.classList.add('hover');
            }, false);
    
            title.addEventListener('mouseleave', function() {
                title.classList.remove('hover');
            }, false);
    
            intro.addEventListener('mouseenter', function() {
                title.classList.add('hover');
            }, false);
    
            insta.addEventListener('mouseenter', function() {
                title.classList.remove('hover');
            }, false);
        }
    }
  }
  onLeaveCompleted() {

  }
  
}

// Don`t forget to export your renderer
export default HomeRenderer;